export const thewisher = {
  global: {
      colors: {
        /* BEGIN: Color Palette Definition */
        ultramarine: {
          dark: "#3D6373",
          light: "#C4EEF2"
        },
        "ultramarine!": "#3D6373",
        eggplant: {
          dark: "#170E17",
          light: "#593659"
        },
        "eggplant!": "#011826",
        "blue!": "#033E8C",
        "orange!": "#C7614D",

        "grey-1": "#f9f9f9",
        "grey-2": "#E0E0E0",
        "grey-3": "#315359",
        "grey-4": "#17282B",

        "accent-1": "orange!",
        /* END: Color Palette Definition */
        /* BEGIN: Mapping Colors to Grommet Namespaces */
        
        background: {
          dark: "grey-4",
          light: "grey-1"
        },

        brand: "ultramarine!",

        control: {
          dark: 'accent-1',
          light: 'accent-1',
          color :{
            dark: 'accent-1',
            light: 'accent-1',
          }
        },
        input: {
          background: "accent-1"
        },
        text: {
          dark: "grey-1",
          light: "grey-4"
        }
      },
      focus: {
        border: {
          color: "ultramarine!"
        }
      }
      /* END: Mapping Colors to Grommet Namespaces */
      /* BEGIN: Mapping Colors to Components */
    },
    heading:{
      color:"text"
    },
    page:{
      color:{
        dark: "text"
      }
    },
    box:{
      color:{
        dark: "text"
      }
    },
    pageHeader:{
      title:{
        color:{
          dark:"text", 
          light:"text"
        }
      }
    }, 
    a:{
      color:{
        dark:"text", 
        light:"text"
      }
    }, 
    anchor:{
      default:{},
      color:{
        dark:"accent-1",
        light:"ultramarine!"
      }
    },
    text:{
      font:{
        family:"", 
      },
      color: "text"
    }, 
    card:{
      body:{
        background:{
          light:"grey-1"
        },
        text: {
          color:"text"
        }
      },
      header:{
        //background:"grey-1",
        text: {
          color:"text"
        },
        background:{
          light:"grey-2", 
          dark:"grey-3"
        },
      },
    }, 
    menu:{
      background: {
        light:"grey-2", 
        dark:"grey-3"
      },
      padding: '0px',
      pad: "none",
      item:{
        align: 'center', 
        pad: "none", 
        padding: "0px", 
        margin: "none",
        box: {
          align: 'center', 
          pad: "none", 
          padding: "0px"
        }, 
      group:{
        container:{
          pad: "none"
        }
      }
      }
    }, 
    formField: {
      label: {
        size: 'small',
        margin: { vertical: '0', bottom: 'small', horizontal: '0' },
        weight: 600,
      },
      border: false,
    },
    /* END: Mapping Colors to Components */
}