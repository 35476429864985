import React from 'react';
import { Box } from 'grommet';
import { Star } from 'grommet-icons';
var uniqid = require('uniqid'); 

function StarRating({grade}){
    const stars = [...Array(5)]
    return (
        <Box direction="row">
            {stars.map((star,i)=><Box key={uniqid()+i}><Star color={i+1<=grade?'orange!':''} /></Box>)}
        </Box>
    );
};

export default StarRating