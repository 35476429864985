import React from "react";
import { Notification } from "grommet";

export default function Appalert({setAlert, alert}) {
  return (
    <Notification
      toast
      message={alert.message}
      status={alert.status || "normal"}
      onClose={() => setAlert() }
      time="4000"
    />
  );
}