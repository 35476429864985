import React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, Link } from 'react-router-dom';
import {Avatar, Box, Text} from "grommet"
import styled from 'styled-components';

const StyledLink = styled(Link)`
    text-decoration: none !important;
    &:hover {
        color: text!;
        text-decoration: none;
        }
  `;

function User(props){
    return (
        <Box overflow="hidden" height="small" width={{min: 'xsmall', max: 'xsmall'}} flex={{ grow: 1, shrink: 1}} align="center">
            <StyledLink to={"/app/users/"+props.id} preventScrollReset={true}>
                <Box direction="column">
                    <Box align="center" pad="small">
                        <Avatar size="large" src={props.img}/>
                    </Box> 
                    <Box background={{color:"background"}}><Text textAlign="center" wordBreak="break-word">{props.name}</Text></Box>
                </Box>
            </StyledLink>   
        </Box>     
    )
}

export default User