import React, {createContext, useState} from 'react';
import {Grommet, grommet} from "grommet";
import {createBrowserRouter, RouterProvider, redirect, ScrollRestoration, Outlet } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Home from './Home'
import ForgotPassword from './ForgotPassword'
import ChangePassword, {loader as changePasswordLoader} from './ChangePassword'
import FamilyWishList from './FamilyWhishList';
import MyList from './MyList';
import Families from './Families';
import ErrorPage from './ErrorPage';
import LoginForm from './LoginForm';
import { deepMerge } from "grommet/utils";
import { thewisher } from './thewisher-theme';
import "./App.css";

export const AlertContext = createContext(null);
export const ThemeContext = createContext(null);
export const UserContext = React.createContext({
  user:"z", 
  setUser: () => {console.log('set user in context')},
});

let history = createBrowserHistory();
history.listen(({ location, action }) => {
  // this is called whenever new locations come in
  // the action is POP, PUSH, or REPLACE
  //history.push(location);
  console.log("history changed to : ", location)
});


// Add a new Layout component
const Layout = () => {
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export default function App(){
  const [user, setUser] = useState(window.localStorage.getItem('user'))
  const [session, setSession] = useState(document.cookie.split("; ").find((row) => row.startsWith("connect.sid="))?.split("=")[1])  

  const updateUser=(data)=>{
    console.log('set user in app state')
    window.localStorage.setItem('user',data)
    setUser(data)
    setSession(data)
  }

  const [isDarkMode, setIsDarkMode] = React.useState(window.localStorage.getItem("darkMode")=="true");

  const switchTheme =()=>{
    localStorage.setItem(('darkMode'), !isDarkMode)
    setIsDarkMode(!isDarkMode)
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: async () => {
            console.log("/ loader, user : ", user)
            if (!session){
              console.log("session not detected, redirecting...")
              return redirect("/login")
            }
            else{
              console.log(" session detected")
              return redirect("/app/my-list")
            }
          }
        },
        {
          path: "app",
          element: <Home darkMode={isDarkMode} switchTheme={switchTheme} updateUser={updateUser}/>,
          loader: async () => {
            console.log("app loader, user : ", user, ", theme : ", isDarkMode)
            if (!session){
              console.log("session not detected, redirecting...")
              return redirect("/login")
            }
          },
          children: [
            {
              path: "/app/my-list",
              element: <MyList switchTheme={switchTheme} updateUser={updateUser}/>
            },
            {
              path: "/app/users/:id",
              element: <FamilyWishList/>
            },
            
            {
              path: "/app/families",
              exact: true,
              element: <Families/>
            }
          ],
        },
        {
          path: "login",
          element: <LoginForm updateUser={updateUser}/>,
          loader: async () => {
            console.log("login loader")
            if (session){
              console.log("session detected, redirecting...")
              return redirect("/")
            }
          },
        },
        {
          path: "forgotpassword",
          element: <ForgotPassword/>
        },
        {
          path: "reset/:userId/:hash",
          element: <ChangePassword />,
          loader: changePasswordLoader,
        },
      ],
    },
  ]);

  return(
    <ThemeContext.Provider value={isDarkMode}>
       <UserContext.Provider value={{user, updateUser}}>
          <Grommet full theme={deepMerge(grommet,thewisher)} themeMode={isDarkMode ? "dark" : "light"} >
          <RouterProvider router={router} />
          </Grommet>
      </UserContext.Provider>
    </ThemeContext.Provider>
  )
}
