import React from 'react'
import {Anchor, Box, Button, CheckBox, Heading, Image, Paragraph, Stack, Tag, Text, Tip} from 'grommet';
import { Edit, Link } from 'grommet-icons';
import StarRating from './components/StarRating';
import styled from 'styled-components';
import { useContext } from 'react';
import { ThemeContext } from './App';
import { UserContext } from './App';

const StyledAnchor = styled(Anchor)`
    text-decoration: none !important;
    &:hover {
    text-decoration: none;
    color: ${props => props.light==true?"#3D6373":"#C7614D"}
    }
`   

export default function Wish3(props){
    const [purchased, setPurchased] = React.useState(props.isPurchased);
    const date = new Date(props.createdAt);
    const {isEdit, setShowEditor, setWishToEdit} = props;
    const recipient = props.recipient || ""

    const theme = useContext(ThemeContext);

    return (
        <>
            { !(props.isPurchased) &&
                <Box height={{min: 'small', max: 'large'}} elevation="small" gap="small" direction='row-responsive' border={{ color: 'grey-2', size: 'xsmall' }}>       
                    {props.img && 
                        <Box flex={{ grow: 0, shrink: 4}} >
                            <Image height={{min: 'small', max: 'medium'}} fill="horizontal" elevation="medium" fit="cover" src={props.img}/>
                        </Box>
                    } 
                    <Box justify="start" direction = "column" width={{min: 'small'}} flex={{ grow: 1, shrink: 3 }} pad={{horizontal:"medium", top:"medium", end:"none"}}> 
                        <Box>
                            <Heading size="small">
                                {props.url ? 
                                    (<><StyledAnchor light={theme==true} href={props.url} label={props.name} rel="noopener noreferrer" target="_blank"/><Link  color="brand" size="small"/></>)
                                    :
                                    (<Box>{props.name}</Box>)
                                }
                                <Box><StarRating grade={props.importance}/></Box> 

                            </Heading>                                                         
                        </Box> 
                        <Box flex={{ grow: 0, shrink: 0}} direction = "row" wrap={true} pad={{end:"medium"}}> 
                            <Box><Tag size="xsmall" name="Ajouté le" value={new Intl.DateTimeFormat('fr-fr').format(date)}/></Box>
                            {props.isSecret && props.creator &&
                                <Box><Tag size="xsmall" name="Une idée de" value={<Text size="xsmall" truncate={true}>{props.creator}</Text>} /></Box>
                            }
                            {props.isSecret && 
                                <Box><Tip plain content={<Box pad="xsmall" round="xsmall" background={"grey-2"}><Text size="small" truncate={true}>Le cadeau est invisible pour {recipient} </Text></Box>}><Tag size="xsmall" value={<><span role="img" aria-label="secret">👻</span> secret</>}/></Tip></Box>
                            }
                        </Box>
                        <Box>
                            <Box overflow={"scroll"}>
                                <Paragraph >{props.comment}</Paragraph>
                            </Box>
                        </Box>
                        
                            
                        <Box flex={{ grow: 1, shrink: 0}} justify="end" alignSelf="end" pad="small"> 
                            <Button  
                                secondary 
                                onClick={()=>{setWishToEdit(props);setShowEditor(!isEdit)}}
                                label={props.isSecret || props.self===true ? <Edit color='text'/> : "Voir"}
                            />
                        </Box>
                    </Box>
            </Box>}
            {(props.isPurchased) &&
                <Box 
                    color={"#f9f9f9"}  
                    background={`linear-gradient(160deg, rgba(23,40,43,1) 70%, rgba(199,97,77,1) 100%);`} 
                    fill={true} 
                    elevation="small" 
                    pad="medium" 
                    gap="xxsmall"
                    direction='column' 
                    border={{ color: 'grey-2', size: 'xsmall' }}
                    justify="between"
                >
                    <Box>
                        {props.url ? 
                            (<StyledAnchor light={theme==true} href={props.url} label={props.name} rel="noopener noreferrer" target="_blank"/>)
                            :
                            (<Heading color={"#f9f9f9"} level="3" wordBreak="break-word">{props.name}</Heading>)
                        }
                        <Text color={"#f9f9f9"} size="medium">
                            Cadeau {props.isSecret && "secret"} acheté {props.purchaseDate && "le: " + new Intl.DateTimeFormat('fr-fr').format(new Date(props.purchaseDate))} <span role="img" aria-label="party emoji">🎉</span>
                        </Text>
                        {props.purchaseDate && (
                            <Text color={"#f9f9f9"} truncate>
                                {props.buyer ? "par " + props.buyer : ""}
                            </Text>
                        )}
                    </Box>
                    <Box alignSelf='start' margin={{ top: 'none' }}>
                        <Button  
                            primary
                            onClick={() => {setWishToEdit(props); setShowEditor(!isEdit)}}
                            label={props.isSecret ? "Modifier" : "Voir"}
                        />
                    </Box>
                </Box>
            }
        </>
    );
}
