import React, {useState} from 'react'
import Appalert from './Appalert'
import userservice from './services/userservice';
import LoginBg from './login-bg2.svg'
import { UserContext } from './App';
import { redirect, ScrollRestoration} from 'react-router-dom';
import { Anchor, Box, Button, Form, FormField, Layer, Page, PageContent, PageHeader, TextInput} from 'grommet';

export default function LoginForm(props){
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openRegister, setOpenRegister] = useState('');

    const [alert, setAlert] = useState('');

    const updateUser = props.updateUser;

    const updateAlert = (message, status)=>{
        setAlert(previousState => {
          return { ...previousState, message: message, status:status }
        });
      }

    const signin =(login,password,setUser)=>{
        console.log('click')
        userservice.signin(login,password)
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            if(data.message){
                updateAlert(data.message,"critical")
                //setUser("")
                localStorage.removeItem('user');
            }
            else{
                //console.log('login ok')
                localStorage.setItem('user', data.userId);
                setUser(data.userId)
                redirect("/")
            }  
        })
        .catch(err=>{
            //this.props.refreshuser(false)
            console.log("found error")
            updateAlert(err.message,"critical")
            //setUser(true)
            localStorage.removeItem('user');
        })
    }
    
    const register=(username,password,email )=>{
    const q = "/api/register"
    return fetch(q,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "name":username,
            "password":password,
            "email":email
        })
    })
    .then(response => response.json())
    .then((data) => {
        console.log(data)
        if(data.message){
            //this.showAlert(data.message,"critical")
            updateAlert(data.message,"critical")
            //this.props.refreshuser(false)
        }
        else{
            updateAlert("Utilisateur créé","success")
            setOpenRegister(false)
        }  
    })
    .catch(err=>{
        console.log(err)
        //this.props.refreshuser(false)
        updateAlert(err,"critical")
    }) 
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (openRegister) {
            register(login, password, email);
        } else {
            signin(login, password, updateUser);
        }
    };

    return (
        <>
            <ScrollRestoration />
            {alert.message && (
                <Appalert setAlert={updateAlert} alert={alert} />
            )}

            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Layer full animation="fadeIn" background={{ image: `url(${LoginBg})`, color: 'light-2' }}>
                        <Box fill="vertical" justify="center" align="center">
                            <Page
                                pad="large"
                                background={{ color: "white", opacity: 0.9 }}
                                width="medium"
                                justify="center"
                                align="center"
                            >
                                <PageHeader title={openRegister ? "The Wisher - Nouvel utilisateur" : "The Wisher - Connexion"} />
                                <PageContent>
                                    <Form onSubmit={handleSubmit}>
                                        {openRegister && (
                                            <FormField label="Adresse email" margin={{ bottom: "small" }}>
                                                <TextInput id="email" placeholder="Adresse email" onChange={(event) => setEmail(event.target.value)} />
                                            </FormField>
                                        )}

                                        <FormField label="Identifiant" margin={{ bottom: "small" }}>
                                            <TextInput id="username" placeholder="Nom d'utilisateur ou adresse email" onChange={(event) => setLogin(event.target.value)} />
                                        </FormField>
                                        <FormField label="Mot de passe" margin={{ bottom: "medium" }}>
                                            <TextInput type="password" id="password" placeholder="Mot de passe" onChange={(event) => setPassword(event.target.value)} />
                                        </FormField>

                                        <Box direction="row" gap="small">
                                            {openRegister ? (
                                                <Button type="submit" label={"S'enregistrer"} />
                                            ) : (
                                                <Button type="submit" primary label={"Se connecter"} />
                                            )}
                                            <Button type="button" label={openRegister ? "Retour" : "Créer un profil"} onClick={() => setOpenRegister(!openRegister)} />
                                        </Box>

                                        <Anchor target="_blank" rel="noopener noreferrer" href="/forgotpassword">Mot de passe oublié ?</Anchor>
                                    </Form>
                                </PageContent>
                            </Page>
                        </Box>
                    </Layer>
                )}
            </UserContext.Consumer>
        </>
    );
    
}
