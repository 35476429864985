import React, { useState, useEffect } from 'react';
import { Box, FormField, Select, Text, Spinner } from 'grommet';

const MutualFriendsDropdown = ({ userId, friendId, onChange, buyerId, buyerName }) => {
  const [mutualFriends, setMutualFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(
    buyerId && buyerName
      ? { label: buyerName, value: buyerId }
      : { label: '', value: '' }
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMutualFriends = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`/api/users/${userId}/mutualfriends/${friendId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch mutual friends');
        }
        const data = await response.json();
        
        // Include the buyer in the list if they're not already present
        if (buyerId && buyerName) {
          const buyerExists = data.some(friend => friend.uid === buyerId);
          if (!buyerExists) {
            data.unshift({ uid: buyerId, name: buyerName });
          }
        }
        
        setMutualFriends(data);

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchMutualFriends();
  }, [userId, friendId]);

  useEffect(() => {
    // Handle buyer and initial selection logic
    if (buyerId && buyerName && mutualFriends.length > 0) {
      const buyerExists = mutualFriends.some(friend => friend.uid === buyerId);
      if (!buyerExists) {
        setMutualFriends(prevFriends => [{ uid: buyerId, name: buyerName }, ...prevFriends]);
      }
    }

    if (!selectedFriend.value && mutualFriends.length > 0) {
      const currentUser = mutualFriends.find(friend => friend.uid === userId);
      if (currentUser) {
        const newSelection = { label: currentUser.name, value: currentUser.uid };
        setSelectedFriend(newSelection);
        onChange(newSelection);
      }
    }
  }, [mutualFriends, userId, buyerId, buyerName, selectedFriend.value, onChange]);

  const handleSelectChange = ({ option }) => {
    setSelectedFriend(option);
    onChange(option); // Call the onChange prop with the selected option
  };

  if (isLoading) return <Box align="center" pad="medium"><Spinner /></Box>;
  if (error) return <Box pad="medium"><Text color="status-error">{error}</Text></Box>;

  return (
    <Box>
      <FormField name="mutualFriends" label="Acheteur">
        <Select
          id="mutualFriends"
          name="mutualFriends"
          value={{
            "label": selectedFriend.label,
            "value": selectedFriend.value
          }}
          onChange={handleSelectChange}
          options={mutualFriends.map(friend => ({
            label: friend.name,
            value: friend.uid
          }))}
          labelKey="label"
          valueKey="value"
        />
      </FormField>
    </Box>
  );
};

export default MutualFriendsDropdown;
