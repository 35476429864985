import React, { useState, useEffect } from 'react';
import {Anchor, Box, Button, CheckBox, Form, FormField, Image, PageContent, PageHeader, Spinner, TextArea, TextInput, DateInput} from 'grommet'
import StarRatings from 'react-star-ratings';
import { debounce } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FormClose, Trash, Checkmark, Copy } from 'grommet-icons';
import { UserContext } from './App';
import MutualFriendsDropdown from './components/MutualFriendsDropdown';



const WishForm = (props) => {
    const [name, setName] = useState(props.wish?.name || "");
    const [importance, setImportance] = useState(props.wish?.importance || 1);
    const [comment, setComment] = useState(props.wish?.comment || "");
    const [url, setUrl] = useState(props.wish?.url || "");
    const [isLoading, setIsLoading] = useState(false);
    const [img, setImg] = useState(props.wish?.img || "");
    const [isPurchased, setIsPurchased] = useState(props.wish?.isPurchased || false);
    const [purchaseDate, setPurchaseDate] = useState(props.wish?.purchaseDate ? props.wish.purchaseDate : null);
    const [purchasedBy, setPurchasedBy] = useState(props.wish?.purchasedBy || null);
    const [isSecret, setIsSecret] = useState(props.wish?.isSecret);
    const [errors, setErrors] = useState({});
    const StyledAnchor = styled(Anchor)`
        text-decoration: none !important;
        &:hover {
        text-decoration: none;
        color: #C7614D;
        }
    `;
  const StyledLink = styled(Link)`
    text-decoration: none !important;
    &:hover {
        text-decoration: none;
        color: #C7614D;
    }
    `;
    const {user} = React.useContext(UserContext)
    const owner = props.owner
    const creator = props.wish?.creator
    const self = props.self
    const isReadOnly = props.editForm===true && isSecret===false && self===false;
    const buttonLabel = "Ajouter à " + (self ? "ma" : "la") + " liste" ;
    const formTitle = (props.editForm ? ( (isSecret ? "Modifier" : "Voir") + " le cadeau "): "Nouveau cadeau ") + (isSecret===true ? "secret 👻" : "🎁")



    const toggle = props.toggle

    function togglePurchased() {
        setIsPurchased((prevIsPurchased) => {
            const newIsPurchased = !prevIsPurchased;
            
            if (newIsPurchased) {
                setPurchaseDate(new Date().toISOString());
                setPurchasedBy(null);
            } else {
                setPurchaseDate(null);
                setPurchasedBy(null);
            }
            
            return newIsPurchased;
        });
    }

    const validateName = (a,fullForm)=>{
        if (!name) {
            return {"message":"Le nom du cadeau ne peut pas être vide", status:"error"};
        }
        return
    }
    const validateURL = (a, fullForm)=>{
        if (url.length > 254) {
            return {"message":"l'URL du site d'achat est trop longue", status:"error"};
        }
        if (url) {
            let validurl
            try {
                validurl = new URL(url);
            } catch (_) {
                return {"message":"l'URL du site d'achat est invalide", status:"error"};
            }
            if( !validurl.protocol === "http:" && !validurl.protocol === "https:"){
                return {"message":"l'URL du site d'achat est invalide", status:"error"};
            }
        }
        return
    }

    const validateComment = (a, fullForm)=>{
        if (comment.length > 254) {
            return {"message":"Le commentaire est trop long", status:"error"};
        }
        return
    }
    const validateImg = (a, fullForm)=>{
        if (img.length > 254) {
            return {"message":"l'URL de l'image est trop longue", status:"error"};
        }
        if(img){
            let validurl
            try {
                validurl = new URL(img);
            } catch (_) {
                return {"message":"l'URL du site d'achat est invalide", status:"error"};
            }
            if( !validurl.protocol === "http:" && !validurl.protocol === "https:"){
                return {"message":"l'URL du site d'achat est invalide", status:"error"};
            }
        }
        return
    }

    const handleValidation = () => {
        let formIsValid = true;
        let tempErrors = {};

        if (validateName()?.status=="error") {
            formIsValid = false;
        }
        if (validateURL()?.status=="error") {
            formIsValid = false;
        }
        if (validateComment()?.status=="error") {
            formIsValid = false;
        }
        if (validateImg()?.status=="error") {
            formIsValid = false;
        }
        return formIsValid;
    };

    const handleURLChange = (event) => {
        const value = event.target.value;
        setUrl(value);
        debounceGetDetails(value);
    };

    const handleDateChange = (date) => {
        setPurchaseDate(date);
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const handleStarClick = (val) => {
        setImportance(val);
    };

    const postwish = () => {
        if (handleValidation()) {
            props.postwish({ 
                name, 
                importance, 
                comment, 
                url, 
                img, 
                isPurchased, 
                isSecret, 
                purchaseDate,
                "buyerUid": purchasedBy, 
                "creatorUid": user
            });
            toggle();
        }
    };

    const editwish = () => {
        if (handleValidation()) {
            props.putwish({ 
                name, 
                importance, 
                comment, 
                url, 
                img, 
                isPurchased, 
                isSecret, 
                purchaseDate,
                "buyerUid": purchasedBy 
            }, props.wish.id);
            toggle();
        }
    };

    const getDetails = (value) => {
        setIsLoading(true)
        props.getWishDetails(value).then((data) => {
            if (!data.message) {
                //setName(data.data.title);
                setImg(data.data.url);
            }
            setIsLoading(false)
        });
    };

    // Create a memoized debounced function
    const debounceGetDetails = React.useMemo(
        () => debounce(getDetails, 500),
        [] // Empty dependency array ensures this is only created once
    );

    // Add this new function to handle the dropdown change
    const handlePurchasedByChange = (selectedUser) => {
        setPurchasedBy(selectedUser?.value);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url).then(() => {
            // Optionally, you can show a temporary message to indicate successful copy
            console.log('URL copied to clipboard');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
    <>
        <PageHeader
            title={formTitle}
            actions={<Button plain = {false} icon={<FormClose/>} secondary onClick={toggle} />}
        />
        <PageContent>
            <Form>
                {self===false && 
                    <FormField name="isPurchased" label="Statut d'achat">
                        <Box direction="row" align="center" gap="small">
                            <CheckBox
                            toggle
                            checked={isPurchased}
                            label={isPurchased ? "Acheté" : "Pas encore acheté"}
                            onChange={togglePurchased}
                            />
                        </Box>
                </FormField>
                }
                {isPurchased && self===false && (
                    <>
                        <FormField name="purchaseDate" label="Date d'achat">
                            <DateInput
                                format="dd/mm/yyyy"
                                value={purchaseDate}
                                onChange={(e) => handleDateChange(e.value)}
                            />
                        </FormField>
                        <MutualFriendsDropdown 
                            userId={user} 
                            friendId={owner} 
                            buyerId={purchasedBy}
                            buyerName={props.wish?.buyer}
                            onChange={handlePurchasedByChange}  // Add this prop
                        />
                    </>
                )}
                {self=== false &&
                    <Box margin={{ vertical: 'medium' }} border="bottom" />
                }

                <FormField name="name" label="Nom du cadeau" validate={validateName} validateOn="submit">
                    <TextInput
                        disabled={isReadOnly}
                        aria-label="Input Text"
                        name="name"
                        id="#Wishname"
                        placeholder="Nom du cadeau"
                        value={name}
                        onChange={(e) => handleInputChange(e, setName)}
                    />
                </FormField>
                <FormField name="priority" label="Priorité">
                    <StarRatings
                        rating={importance}
                        starRatedColor="rgb(255, 180, 0)"
                        starHoverColor="rgb(255, 180, 0)"
                        changeRating={isReadOnly ? null : handleStarClick}
                        numberOfStars={5}
                        name='rating'
                    />
                </FormField>
                {self=== false && creator && <FormField name="creator" label="Une idée de">
                    <TextInput
                        disabled={true}
                        name="creator"
                        id="#Wishcreator"
                        value={creator}
                    />
                </FormField>}
                <FormField name="url" label="URL" validate={validateURL} validateOn="submit">
                    <Box direction="row" align="center">
                        <TextInput
                            disabled={isReadOnly}
                            name="url"
                            id="#Url"
                            placeholder="Lien vers le site d'achat"
                            value={url}
                            onChange={(e) => handleURLChange(e, setUrl)}
                        />
                        { url && (
                            <Button
                                icon={<Copy />}
                                onClick={copyToClipboard}
                                tip="Copier l'URL"
                                plain
                                margin={{ left: 'small' }}
                            />
                        )}
                    </Box>
                </FormField>
                <FormField name="comment" label="Commentaire" validate={validateComment} validateOn="submit">
                    <TextArea
                        disabled={isReadOnly}
                        name="comment"
                        id="#Description"
                        placeholder="Commentaire"
                        value={comment}
                        onChange={(e) => handleInputChange(e, setComment)}
                    />
                </FormField>
                    <FormField name="img" label="URL de l'image" validate={validateImg} validateOn="submit">
                        <TextInput
                            disabled={isReadOnly}
                            name="img"
                            id="#Wishimg"
                            placeholder={isLoading ? <Spinner/> : "Lien vers l'image"}
                            value={img}
                            onChange={(e) => handleInputChange(e, setImg)}
                        />
                    </FormField>
                {img && (
                <FormField label="Aperçu de l'image">
                    <Box fill="horizontal" direction="row" justify='start'><Box pad="xsmall" border round="xsmall" width="small" height={{ max: 'medium' }} ><Image fit="contain" src={img}></Image></Box></Box>
                </FormField>
                )}
                <Box align="end">
                    {props.editForm ? (
                    <Box pad="xsmall" direction="row" wrap={true}>
                        <Button margin="xxsmall" onClick={toggle} label="Annuler" />
                        {!isReadOnly && <Button margin="xxsmall" onClick={() => { props.deletewish(props.wish.id); toggle(); }} icon={<Trash/>} label="Supprimer" />}
                        <Button margin="xxsmall" type="submit" primary onClick={editwish} label="Valider" />
                    </Box>
                    ) : (
                    <Box direction="row" align="center" gap="small" pad="xsmall">
                        <Button margin="xxsmall" onClick={toggle} label="Annuler" />
                        <Button margin="xxsmall" type="submit" primary onClick={postwish} label={buttonLabel} />
                    </Box>
                    )}
                </Box>
            </Form>
        </PageContent>
    </>);
};

export default WishForm;
