import React, { useState, useEffect } from 'react';
import Appalert from './Appalert';
import { Box, Button, Heading, Menu, Page, PageContent, PageHeader, Spinner, Text } from 'grommet';
import JoinFamilyForm from './JoinFamilyForm';
import CreateFamilyForm from './CreateFamilyForm';
import Family from './Family';
import { Add, AddCircle, Group } from 'grommet-icons';

const Families = (props) => {
    const [families, setFamilies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showFamilyJoinForm, setShowFamilyJoinForm] = useState(false);
    const [showFamilyCreation, setShowFamilyCreation] = useState(false);
    const [alert, setAlert] = useState("");

    const updateAlert = (message, status)=>{
        //console.log("setting alert", message)
          setAlert(previousState => {
          return { ...previousState, message: message, status:status }
        });
    }

    useEffect(() => {
        fetch('/api/families/members/me')
            .then((response) => response.json())
            .then((responseJson) => {
                setIsLoading(false);
                if (responseJson.Families) {
                    setFamilies(responseJson.Families);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const toggleJoinFamilyForm = () => {
        setShowFamilyJoinForm(!showFamilyJoinForm);
    };

    const toggleFamilyCreation = () => {
        setShowFamilyCreation(!showFamilyCreation);
    };

    const joinFamily = (familycode, familyname) => {
        const user = "me";
        return fetch('/api/families/join/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                familyname: familyname,
                code: familycode,
                uid: user
            })
        })
        .then((response) => {
            response.json()
            .then((responseJson) => {
                if (response.ok) {
                    updateAlert("Bienvenue dans la famille", "normal");
                    setFamilies(prevFamilies => [...prevFamilies, responseJson]);
                    setIsLoading(false);
                }
                else {
                    throw new Error (responseJson.message)
                }
            })
            .catch((error) =>{
                updateAlert(error.message,"critical")
                console.error(error);
            });
        })
        .catch((error) =>{
            updateAlert(error.message,"critical")
            console.error(error);
        });
    };

    const createFamily = (familycode, familyname) => {
        return fetch('/api/families/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: familyname,
                code: familycode
            })
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error();
            }
        })
        .then((responseJson) => {
            updateAlert("Famille créée", "normal");
            setFamilies(prevFamilies => [...prevFamilies, responseJson]);
            setIsLoading(false);
        })
        .catch((error) => {
            updateAlert(error.message, "critical");
            console.error(error);
        });
    };

    const familyArray = families
        .sort((item1, item2) => item2.Users.length - item1.Users.length)
        .map(item => (
            <Family 
                key={item.uid} 
                name={item.name} 
                code={item.code} 
                id={item.uid} 
                nav={props.nav} 
                users={item.Users} 
            />
        ));

    return (
        <Page kind="narrow">
            <PageHeader
                title="Familles"
                responsive
                actions={
                    <Menu
                        label={"Rejoindre/créer"}
                        items={[
                            {
                                label: (<Box pad="large">Rejoindre une famille existante</Box>),
                                icon:(<Box pad="large"> <Group /></Box>),
                                onClick:()=>{toggleJoinFamilyForm()}

                            },
                            {
                                label: (<Box pad="large">Créer une nouvelle famille</Box>),
                                icon:(<Box pad="large"> <Add /></Box>),
                                onClick:()=>{toggleFamilyCreation()}
                            }
                        ]}
                    />
                }
            />
            <PageContent width={{min:"small", max:"xlarge"}}>
                <Box gap='small'>
                    {isLoading && (
                        <Box>
                            <Spinner />"Chargement des familles..."
                        </Box>
                    )}
                    {alert.message&&
                        <Appalert setAlert={updateAlert} alert={alert}></Appalert>
                    }
                    {familyArray}
                </Box>

                {familyArray.length === 0 && !isLoading && (
                    <Text>
                        Oh! Il n'y a personne ici <span role="img" aria-label="triste">😿</span>
                    </Text>
                )}

                <Box>
                    {showFamilyJoinForm && (
                        <JoinFamilyForm open={showFamilyJoinForm} toggle={toggleJoinFamilyForm} joinFamily={joinFamily} />
                    )}
                    {showFamilyCreation && (
                        <CreateFamilyForm createFamily={createFamily} open={showFamilyCreation} toggle={toggleFamilyCreation} />
                    )}
                </Box>
            </PageContent>
        </Page>
    );
};

export default Families;
